import { createStore, atom } from "jotai";
import type {
  User,
  TypesafeClient,
  Workspace,
  WorkspaceBillingStatus,
} from "~/types";

export const rootStore = createStore();
export const supabaseAtom = atom<TypesafeClient>(
  null as unknown as TypesafeClient
);

export const userAtom = atom<User>(null as unknown as User);
export const workspaceAtom = atom<Workspace>(null as unknown as Workspace);
export const workspaceBillingAtom = atom<WorkspaceBillingStatus>(
  null as unknown as WorkspaceBillingStatus
);
export const workspaceRoleAtom = atom((get) => {
  const workspace = get(workspaceAtom);
  const user = get(userAtom);
  return workspace.workspace_users.find((wu) => wu.user_id === user.id)!.role;
});

const SubscriptionLevel = new Map([
  ["Free", 0],
  ["Basic", 1],
  ["Standard", 2],
  ["Team", 3],

  // legacy
  ["Hobby", 2],
  ["Starter", 2],
  ["Pro", 3],
]);
export const subscriptionLevelAtom = atom((get) => {
  const subscription = get(workspaceBillingAtom);
  const level = SubscriptionLevel.get(subscription.plan_name) ?? 0;
  return level;
});

export const subscriptionStatusAtom = atom((get) => {
  const subscription = get(workspaceBillingAtom);
  return subscription.status;
});

export const isTrialSubscriptionAtom = atom<boolean>((get) => {
  const subscription = get(workspaceBillingAtom);
  return ["trialing"].includes(subscription.status);
});

export const isActiveSubscriptionAtom = atom<boolean>((get) => {
  const subscription = get(workspaceBillingAtom);
  return ["active", "trialing"].includes(subscription.status);
});
